  import React from 'react'
  import Layout from '../../components/App/Layout'
  import Navbar from '../../components/App/NavbarRV'
  import Footer from '../../components/App/FooterRV'
  import KDPImagePage from '../../assets/images/pages/2-0-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li>Expertise d'assuré</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’expertise d’assuré, la défense de vos intérêts</h1>

<p>Vous avez subi un sinistre sur votre habitation à la suite à une catastrophe naturelle ? </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expertise d'assuré" />
</div>

<p>Votre assurance n’indemnise pas vos dommages à leur juste valeur ? pire, l’assurance ne garantie pas votre sinistre ? </p>

<p>Vous avez la possibilité de vous faire assister par un Expert d’assuré qui aura pour mission <strong>de défendre strictement vos intérêts.</strong></p>

<p>L’expert d’assuré n’est pas un expert d’assurance.</p>

<p>L’expert d’assuré est un professionnel indépendant, neutre et impartial.</p>

<p>Il est mandaté par l’assuré.</p>

<p>L’expert d’assuré intervient donc pour le compte d’un assuré dans les contextes suivants :</p>

<ul><li>litige avec l’assurance,</li>
<li>après un sinistre,</li>
<li>assistance en expertise judiciaire,</li></ul>






                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-assure/' className='active'>Expertise d'assuré</a></li><li><a href='/expertise-assure/expert-assure/' >L'expert d'assuré</a></li><li><a href='/expertise-assure/garanties/' >Garanties</a></li><li><a href='/expertise-assure/risques/' >Catastrophe Naturelle</a></li><li><a href='/expertise-assure/incendie/' >Après un incendie</a></li><li><a href='/expertise-assure/expertise-judiciaire/' >Expertise contexte judiciaire</a></li><li><a href='/expertise-assure/degats-eaux/' >Dégat des eaux</a></li><li><a href='/expertise-assure/tempete/' >Tempête</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details